import deepLearning from "../../images/courses/deepLearning.jpg";
import machineLearn from "../../images/courses/machineLearn.png";
import nodejs from "../../images/courses/nodejs.png";
import OpenCV from "../../images/courses/OpenCV.jpg";
import SolidWorks from "../../images/courses/SolidWorks.jpg";
import ROSwithCPP from "../../images/courses/ROSwithCPP.png";
// import reactNative from "../../images/courses/reactNative.jpg";.
import AhmedElsayes from "../../images/courses/profile_photos/AhmedElsayes.jpg";

type props = {
    key: number;
    header: string;
    body: string;
    imgLink: string;
    instructor: string;
    instructorImg: string;
    students: number;
    courseOutcomes: string[];
    courseCategory: string[];
    courseSubCategories: string[][];
    courseSections: string[];
    courseSubSections: string[][];
    courseLessons: string[];
    linkToCourse: string;
}
const courses:props[] = [{
    key: 0,
    header: "Deep learning",
    body: "Develop full understanding of neural networks and apply what you learnt to real application",
    imgLink: deepLearning,
    instructor: "Ahmed Elsayes",
    instructorImg: AhmedElsayes,
    students: 489,
    courseOutcomes: ["none", "none", "none", "none"],
    courseCategory: ["Artificial Intelligence", "Robotics"],
    courseSubCategories: [["NeuralNetworks", "TensorFlow", "gradient descent"], ["images classification", "Mathematics"]],
    courseSections: ["Section 1", "Section 2"],
    courseSubSections: [["Quick introduction to AI", "Basics of Neural Networks"], ["IDE Setup", "indexing with Numpy"]],
    courseLessons: ["F-2rrtG33Cc", "5VjqRUCEYc4", "6T6E3d7DnlU"],
    linkToCourse: "/deep-learning"
},
{
    key: 1,
    header: "Machine learning",
    body: "Learn all algorithms that are used in machine learning and build a real applications with what you learnt",
    imgLink: machineLearn,
    instructor: "Ahmed Elsayes",
    instructorImg: AhmedElsayes,
    students: 923,
    courseOutcomes: ["none", "none", "none", "none"],
    courseCategory: ["Artificial Intelligence", "Data Science"],
    courseSubCategories: [["NeuralNetworks", "TensorFlow", "gradient descent"], ["images classification", "Mathematics"]],
    courseSections: ["Section 1", "Section 2"],
    courseSubSections: [["Quick introduction to AI", "Basics of Neural Networks"], ["IDE Setup", "indexing with Numpy"]],
    courseLessons: ["F-2rrtG33Cc", "5VjqRUCEYc4", "6T6E3d7DnlU"],
    linkToCourse: "/machine-learning"
},
{
    key: 2,
    header: "ReactJs & TypeScript for developing webpages",
    body: "Take the route to be a professional front-end developer who can develop an amazing user interface for web applications using the latest web technologies",
    imgLink: nodejs,
    instructor: "Ahmed Elsayes",
    instructorImg: AhmedElsayes,
    students: 1731,
    courseOutcomes: ["Build an amazing User Interface with ReactJs", "Master the most demanded skills such as ReactJs and TypeScript to be a professional Front-end developer", "Learn about using 3rd party libraries such as Material UI and BootStrap to  build fantastic and responsive web pages", "Applying what you learn practically by developing complete React Application"],
    courseCategory: ["Web Development", "Internet of Things"],
    courseSubCategories: [["HTML", "CSS", "JavaScript","TypeScript","ReactJs","BootStrap","MaterialUI"], ["HTML", "JavaScript"]],
    courseSections: ["Section 1", "Section 2"],
    courseSubSections: [["Quick introduction to AI", "Basics of Neural Networks"], ["IDE Setup", "indexing with Numpy"]],
    courseLessons: ["W0Lu8mn_R30", "IJcfAmQd4jc", "g8bUXHX3bRI","6T6E3d7DnlU","sQHOJ9nrfv8","989Oml8R77M","d5G0jAJ_6uA","gdlRKu7Bz2s","g523kNJkcTs","_BGvYnAcBV8","M88oxBDDIGQ","iXWXa2xpRC4","n0ZO-thRk54","CRUQd3hN5PA","F-2rrtG33Cc","5VjqRUCEYc4","ZbEJWVxNNXc","diA-b7aBBpI","UXPhILj0Veg","LLbhVIQreUg","9gLeZ1lzQ9I","rFMI6siLqMc","U1BPI4iZ9ns","VCPTnKv1o5s"],
    linkToCourse: "/react-course"
},
{
    key: 3,
    header: "Master ROS with linux & C++",
    body: "Master robotic systems programming by using Robot Operating System (ROS) to build an advanced robotic systems with C++ and linux",
    imgLink: ROSwithCPP,
    instructor: "Ahmed Elsayes",
    instructorImg: AhmedElsayes,
    students: 594,
    courseOutcomes: ["Build an amazing User Interface with ReactJs", "Master the most demanded skills such as ReactJs and TypeScript to be a professional Front-end developer", "Learn about using 3rd party libraries such as Material UI and BootStrap to  build fantastic and responsive web pages", "Applying what you learn practically by developing complete React Application"],
    courseCategory: ["Robotics"],
    courseSubCategories: [["ROS", "Sensors", "Actuators"]],
    courseSections: ["Section 1", "Section 2"],
    courseSubSections: [["Quick introduction to AI", "Basics of Neural Networks"], ["IDE Setup", "indexing with Numpy"]],
    courseLessons: ["F-2rrtG33Cc", "5VjqRUCEYc4", "6T6E3d7DnlU"],
    linkToCourse: "/ROS"
},
{
    key: 4,
    header: "3D modeling with solidworks",
    body: "The new indudtrial revolution in digital printing and additive manufacturing created an great need for talented mechanical designers.",
    imgLink: SolidWorks,
    instructor: "Ahmed Elsayes",
    instructorImg: AhmedElsayes,
    students: 741,
    courseOutcomes: ["none", "none", "none", "none"],
    courseCategory: ["Robotics", "Mechanical Design"],
    courseSubCategories: [["NeuralNetworks", "TensorFlow", "gradient descent"], ["images classification", "Mathematics"]],
    courseSections: ["Section 1", "Section 2"],
    courseSubSections: [["Quick introduction to AI", "Basics of Neural Networks"], ["IDE Setup", "indexing with Numpy"]],
    courseLessons: ["F-2rrtG33Cc", "5VjqRUCEYc4", "6T6E3d7DnlU"],
    linkToCourse: "/solid-works"
},
{
    key: 5,
    header: "Complete guide to computer vision with python",
    body: "Learn how to build a fancy computer vision applications by using OpenCV module in python",
    imgLink: OpenCV,
    instructor: "Ahmed Elsayes",
    instructorImg: AhmedElsayes,
    students: 1121,
    courseOutcomes: ["Learn how to use OpenCV module in python to build computer vision applications", "Learn using some external modules in python such as numpy and matPlot to analyze data and visualize results", "Learn the princibles of image processing","Learn editing images and video frames in OpenCV", "Using openCV to create Color Histograms and recognize image and video features based on color variations", "Track and recognize different mobile objects in videos", "Identify objects in images or videos based on its Geometry"],
    courseCategory: ["Artificial Intelligence", "Robotics"],
    courseSubCategories: [["Computer Vision with OpenCV", "Python", "numpy", "matPlot"], ["Computer Vision with OpenCV", "Python", "numpy"]],
    courseSections: ["Section 1", "Section 2"],
    courseSubSections: [["Quick introduction to AI", "Basics of Neural Networks"], ["IDE Setup", "indexing with Numpy"]],
    courseLessons: ["6KY9IHIe94s", "UJwT6sysZNo", "2Pj-7qtkLA8","QMaePPIg8Ks","FttFqcoaSFI","rjXFwQxY9qo","_zuH2ZNje28","y8LiukcB6Q0","tjUibeDHH9s","8l7xFmIJMyU","5KBOUL3cel0","NbEUDEdwqLM","yIRXBt-I0Ck","sj-Ga4QEYSU","q8Wpds2MaWc","0jgY4pBDbuc","VRPZBUd8Yko","BfcSV86kt7o"],
    linkToCourse: "/computer-vision"
},
];

export default courses;
export const VisionCourse = courses[5];
export const Reactcourse = courses[2];