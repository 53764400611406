import React from "react";
import RoutingEN from "./RoutingEN";
import Footer from "./Footer";
// import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div>
      <RoutingEN />
      <Footer />
    </div>
  );
}
export default App;