import AhmedElsayes from "../../images/courses/profile_photos/AhmedElsayes.jpg";

type props = {
    key: number;
    photo: string;
    Name: string;
    job: string;
    shortDescription: string;
    facebookLink: string;
    linkedinLink: string;
    twitterLink: string;
};

const BoardMembers: props[] = [
// {
//     key: 0,
//     photo: AhmedElsayes,
//     Name: "Ahmed Elsayes",
//     job: "Automation Engineer",
//     facebookLink: "",
//     linkedinLink: "https://www.linkedin.com/in/ahmed-elsayes-a26b665b/",
//     twitterLink: ""
// },
// {
//     key: 1,
//     photo: AhmedElsayes,
//     Name: "Ahmed Elsayes",
//     job: "Automation Engineer",
//     facebookLink: "",
//     linkedinLink: "https://www.linkedin.com/in/ahmed-elsayes-a26b665b/",
//     twitterLink: ""
// },
{
    key: 0,
    photo: AhmedElsayes,
    Name: "Ahmed Elsayes",
    job: "Automation Engineer",
    shortDescription: "Ahmed works as an automation engineer in PicoSun which is a leading manufacturer of industrial machines.",
    facebookLink: "",
    linkedinLink: "https://www.linkedin.com/in/ahmed-elsayes-a26b665b/",
    twitterLink: ""
}
];

export default BoardMembers;