import React from "react";
import img from "../../images/underConstruction.jpg"

export default function ScholarshipForm() {
  return (
    <div className="learning_path">
      <p>Here we will publish the scholarship policies</p>
      <img src={img} alt="page" width="800" height="500"></img>
    </div>
  );
}

