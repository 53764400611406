import React, { useState } from "react";
import Vacancy from "./Vacancy";
import VacancyDetailed from "./VacancyDetailed";
import ListOfVacancies from "./ListOfVacancies";

function Vacancies() {

    const [jobDetails, setJobDetails] = useState({
        key: 0,
        vacancyTitle: "",
        VacancyDescription: "",
        VacancyCategory: "",
        Requirements: [""],
        Application: "",
        deadLine: ""
    });
    const [pubDate, setPubDate] = useState("");
    const [trigger, setTrigger] = useState(false);

    function showDetails(datePub: string, id:number) {
        // setJobDetails(jobDETAIL);
        setJobDetails(ListOfVacancies[id])
        setPubDate(datePub);
        setTrigger(true);
    }

    return (
        <div className="VacanciesPage" id="VacanciesPage">
            <div className="VacanciesList">
                <p className="VacanciesList-head">Open Vacancies</p>
                {ListOfVacancies.map((vacancy,Index) => {
                    return (
                        <Vacancy key={Index} index={Index} title={vacancy.vacancyTitle} writeDetails={showDetails} />
                    )
                })}
            </div>
            <div className="VacancyDetails">
                {trigger ? <VacancyDetailed
                    key={jobDetails.key}
                    vacancyTitle={jobDetails.vacancyTitle}
                    VacancyDescription={jobDetails.VacancyDescription}
                    VacancyCategory={jobDetails.VacancyCategory}
                    jobRequirements= {jobDetails.Requirements}
                    Application={jobDetails.Application}
                    deadLine={jobDetails.deadLine}
                    publishDate={pubDate} /> :null}
            </div>
        </div>
    );
}

export default Vacancies;
