import React from "react";
import img from "../../images/underConstruction.jpg"

export default function InstructorSignUpForm() {
  return (
    <div className="learning_path">
      <p>Form for instructors signUp</p>
      <img src={img} alt="page" width="800" height="500"></img>
    </div>
  );
}

