import React, {useState} from "react";
import courses from "./CourseList";
import CourseCard from "./CourseCard";
// import faker from 'faker';
import classnames from "classnames";
// import scholarship from "../../images/scholarship.png";
// import charity from "../../images/charity.jpg";
// import CommunitySupport from "../../images/CommunitySupport.jpg";
import EducateWith from "../../images/iconsEducation/team.svg";
import GoInvestment from "../../images/iconsEducation/profits.svg";
import BoardMembers from "../AbotUs/BoardMembers";
import SingleMember from "../AbotUs/SingleMember";
// List of Icons
import door from "../../images/iconsEducation/door-open.svg";
import job from "../../images/iconsEducation/job-search.svg";
import technology from "../../images/iconsEducation/technology.svg";
import newspaper from "../../images/iconsEducation/newspaper.svg";
import techQuestion from "../../images/iconsEducation/techQuestion.svg";
import diploma from "../../images/iconsEducation/diploma.svg";
import customer from "../../images/iconsEducation/customer-question.svg";
import cloudComputing from "../../images/iconsEducation/categories/cloud.svg";
import cyberSecurity from "../../images/iconsEducation/categories/cyberSecurity.svg";
import robot from "../../images/iconsEducation/categories/robot.svg";
import artificialIntelligence from "../../images/iconsEducation/categories/artificial-intelligence2.svg";
import dataBase from "../../images/iconsEducation/categories/dataBase.svg";
import webDevelopment from "../../images/iconsEducation/categories/webDevelopment.svg";
import smartphone from "../../images/iconsEducation/categories/smartphone.svg";
import wireless from "../../images/iconsEducation/categories/wireless.svg";


export default function Education() {
  const [navWidth, setNavWidth] = useState(false);

  return (
    <div className="Education-page">
      <div className="Head-courses-parallax">
        <div className="coursesGroup-heighlight">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="courseCategouries-text">Learning is more effective and easier on Arabs Technopedia</div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="courseCategouries">
                <div className="courseCategoury">
                  <img src={cloudComputing} alt="cloudComputing"/>
                  <p>Cloud Computing</p>
                </div>
                <div className="courseCategoury">
                  <img src={cyberSecurity} alt="cyberSecurity"/>
                  <p>Cyber Security</p>
                </div>
                <div className="courseCategoury">
                  <img src={robot} alt="robot"/>
                  <p>Robotics and Digital manufacturing</p>
                </div>
                <div className="courseCategoury">
                  <img src={artificialIntelligence} alt="artificialIntelligence"/>
                  <p>Artificial Intelligence</p>
                </div>
                <div className="courseCategoury">
                  <img src={dataBase} alt="dataBase"/>
                  <p>Data Science</p>
                </div>
                <div className="courseCategoury">
                  <img src={webDevelopment} alt="webDevelopment"/>
                  <p>Web Development</p>
                </div>
                <div className="courseCategoury">
                  <img src={smartphone} alt="smartphone"/>
                  <p>Mobile Apps Development</p>
                </div>
                <div className="courseCategoury">
                  <img src={wireless} alt="wireless"/>
                  <p>IOT and Automation Engineering</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="EducationalPlatform">
        <div className={classnames("sidenav", {
            "sidenav--show": navWidth
          })}>
          {/* <button className="closebtn" onClick={() => {setNavWidth(!navWidth);}}><i className="fas fa-chevron-circle-down"></i></button> */}
          <i className="fas fa-chevron-circle-down" onClick={() => {setNavWidth(!navWidth);}}></i>
          <a href="/signUp">
            <img src={door} alt="door"/>
            <div className="text-side-NavBar">SignIn</div>
          </a>
          <a href="/">
            <img src={job} alt="job"/>
            <div className="text-side-NavBar">Jobs</div> 
          </a>
          <a href="/">
            <img src={technology} alt="technology"/>
            <div className="text-side-NavBar">Latest technologies</div> 
          </a>
          <a href="/">
            <img src={newspaper} alt="newspaper"/>
            <div className="text-side-NavBar">Articles</div> 
           </a>
          <a href="/">
            <img src={techQuestion} alt="techQuestion"/>
            <div className="text-side-NavBar">Technical questions</div>
          </a>
          <a href="/">
            <img src={diploma} alt="diploma"/>
            <div className="text-side-NavBar">Scholarships</div>
          </a>
          <a href="/">
            <img src={customer} alt="customer"/> 
            <div className="text-side-NavBar">FQA</div> 
          </a>
        </div>
        {/* <span className="sideBarTap" onClick={() => {setNavWidth(true);}}>&#9776; open</span> */}
        <div className="all_courses">
          {courses.map((course, index) => {
            return (
              <CourseCard
                key={index}
                imgLink={course.imgLink}
                header= {course.header}
                body={course.body}
                instructor={course.instructor}
                instructorImg={course.instructorImg}
                students={course.students}
                categories={course.courseCategory}
                linkToCourse={course.linkToCourse} />
            )
          })}
        </div>
      </div>
      <div className="meet_objectives-education-parallax">
        <div className="meet_objectives">
          <div className="note_all">
            <div className="note">
              <img src={diploma} alt="jobHunt"/>
              <h1> Consider a scholarship</h1>
              <p>We are a non-profit organization. Therefor, we are commited to grant a scholarship to the youth who are highly motivated to work on their dreams. You can now learn for free if the expenses is the only thing stand between you and your dreams</p>
              <div className="learn_more_button">
                <a href="/">Apply</a>
              </div>
            </div>
            <div className="note">
              <img src={GoInvestment} alt="launchStart"/>
              <h1> Make an Investment </h1>
              <p>There are instructors who devote their full time for giving highest quality tutorials. That is why there are some paid courses. If you share us the same values and want to help us offer more scholarships and help in establishing startUps, you can invest with us!</p>
              <div className="learn_more_button">
                <a href="/">invest</a>
              </div>
            </div>
            <div className="note">
              <img src={EducateWith} alt="entrepreneur"/>
              <h1> Educate with us</h1>
              <p>participate with us to help thosands of motivated youth in Arabs world and revive hopes of millions in this part of the world. Jump to another level of expertise in your field by teaching it to the others and highlight your capability in front of many organizations.</p>
              <div className="learn_more_button">
                <a href="/">Educate</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-instructor">
        <div className="container">
          <div className="theme-title text-center">
						<h2>Our Instructors</h2>
						{/* <p>People standing behind this initiative</p> */}
          </div>
            <div className="row">
              {BoardMembers.map((member, index) => {
                return (
                  <SingleMember
                    key={index}
                    photo={member.photo}
                    Name={member.Name}
                    job={member.job}
                    facebookLink={member.facebookLink}
                    linkedinLink={member.linkedinLink}
                    twitterLink={member.twitterLink} />
                  )
              })}
            </div>
        </div>
      </div>
  </div>
  );
}

