import React from "react";

type props = {
    key: number;
    photo: string;
    Name: string;
    job: string;
    facebookLink: string;
    linkedinLink: string;
    twitterLink: string;
};

export default function SingleMember(member:props) {

    return (
        <div className="single-member">
            <div className="image">
                <img src={member.photo} alt="personalPhoto"/>
                <div className="opacity tran4s">
                    <ul>
                        <li>
                            <a href={member.facebookLink} className="member-social tran3s">
                                <i className="fab fa-facebook-f" aria-hidden="false"></i>
                            </a>
                        </li>
                        <li><a href={member.twitterLink} className="member-social tran3s"><i className="fab fa-twitter" aria-hidden="false"></i></a></li>
                        <li><a href={member.linkedinLink} className="member-social tran3s"><i className="fab fa-linkedin-in" aria-hidden="true"></i></a></li>
                    </ul>
                </div>
            </div>
            <h6><a href="/" className="tran3s">{member.Name}</a></h6>
            <p>{member.job} &amp; Developer</p>
        </div> 
    )
}