import React, { useState, useEffect } from "react";

export default function Statistics() {

    const courseNum = 600;
    const studentNum = 500;
    const projectNum = 700;
    const ratingNum = 400;
    const [studentCount, setStudentCount] = useState(0);
    const [courseCount, setCourseCount] = useState(0);
    const [projectCount, setProjectCount] = useState(0);
    const [ratingCount, setRatingCount] = useState(0);
    const [triggerCount, setTriggerCount] = useState(false);
    
    let index = 0;
    function controlStudent() {
        index++;
        if (index<=studentNum) {
            setStudentCount(index);
        }
    }
    function controlProject() {
        index++;
        if (index<=projectNum) {
            setProjectCount(index);
        }
    }
    function controlCourse() {
        index++;
        if (index<=courseNum) {
            setCourseCount(index);
        }
        // console.log(index);
    }
    function controlRating() {
        index++;
        if (index<=ratingNum) {
            setRatingCount(index);
        }
    }

    const handleScroll = () => {
        const currentOffset = window.pageYOffset;
        // console.log(currentOffset);
        if (currentOffset >= 2050) {
            setTriggerCount(!triggerCount);
        } else {
            setTriggerCount(!!triggerCount);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
          }
    },[])

    useEffect(() => {
        // console.log(triggerCount);
        const paramCount1 = setInterval(controlStudent, 30);
        const paramCount2 = setInterval(controlProject, 30);
        const paramCount3 = setInterval(controlCourse, 30);
        const paramCount4 = setInterval(controlRating, 30);
        // console.log(index);
        return () => {
            clearInterval(paramCount1);
            clearInterval(paramCount2);
            clearInterval(paramCount3);
            clearInterval(paramCount4);
        }
    },[triggerCount])

    return (
        <div className="theme-counter">
            <div className="opacity">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 col-xs-6">
                            <h3>{studentCount} +</h3>
                            <p>Students</p>
                        </div>
                        <div className="col-sm-3 col-xs-6">
                            <h3>{courseCount} +</h3>
                            <p>Courses</p>
                        </div>
                        <div className="col-sm-3 col-xs-6">
                            <h3>{projectCount} +</h3>
                            <p>Projects</p>
                        </div>
                        <div className="col-sm-3 col-xs-6">
                            <h3>{ratingCount} +</h3>
                            <p>Ratings</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}