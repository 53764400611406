import React from "react";
import Statistics from "./Statistics";
import technopediaLogo from "../../images/technopediaLogo.gif";
import projectLogo from "../../images/1.png";
import learnPathsLogo from "../../images/learnPathsLogo.png";
import Talent from "../../images/Talent-pools.png";
import target from "../../images/moving-target.jpg";
import BoardMembers from "./BoardMembers";
import SingleMember from "./SingleMember";
import vision from "../../images/computerVision.jpg";
import tunnel from "../../images/tunnel.jpg";

export default function AboutUsNew() {
    return (
        <div className="AboutUsPage-wrapper">
            <div className="Head-AboutUs-parallax">
                <div className="Head-AboutUs">
                    <div className="row">
                        <div className="col head-slide">
                            <h1 className="animate__animated animate__backInDown"> About us</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <img src={technopediaLogo} alt="path to future" className="pic-main-slider animate__animated animate__fadeInLeft animate__delay-2s"/>
                        </div>
                        <div className="col ">
                            <img src={projectLogo} alt="path to future" className="pic-main-slider pic-slider animate__animated animate__fadeInUp animate__delay-1s"/>
                        </div>
                        <div className="col">
                            <img src={learnPathsLogo} alt="path to future" className="pic-main-slider animate__animated animate__fadeInRight animate__delay-2s"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-text">
				<div className="container">
					<div className="wrapper">
						<div className="row">
							<div className="col-sm-6 col-xs-12">
								<img src={Talent} alt="Mission" className="space"/>
								<div className="theme-title">
									<h6>Title here</h6>
									<h2>Our Mission</h2>
								</div>
								<p>Our mission is to help Arab Youth around the globe to fulfil their dreams by giving them a top-class education and helping them to land the job of their dreams or establishing their own business.</p>
							</div>
							<div className="col-sm-6 col-xs-12 m-fix">
								<div className="theme-title">
									<h6>About us</h6>
									<h2>Who We Are</h2>
								</div>
								<p className="space">ASEEU stands for “Arab scientists and engineers in Europe”. ASEEU is started as an initiative by small group of young men living in Finland and Egypt. Our main motif was to help youth in Arabic world to get a high-quality education through online media channels without having the proficiency of English as a barrier that hold them from pursuing their dreams.</p>
								<img src={target} alt="About"/>
							</div>
						</div>

						<div className="row additional-group-aboutUs">
							<div className="col-sm-6 col-xs-12">
								<img src={vision} alt="Dream" className="space"/>
								<div className="theme-title">
									<h2>Our Dream</h2>
								</div>
								<p>We started this initiative because we believe in the potential of Arab youth and we hope that in return this will accelerate the development of Arab states. We believe that positive results will foster the connection between the Arab expats and their homelands, boosting the road for their return by creating a sustainable development-based societies in their homelands.</p>
							</div>
							<div className="col-sm-6 col-xs-12 m-fix">
								<div className="theme-title">
									<h2>Our vision</h2>
								</div>
								<p className="additional-space-vision">We hope soon to have many Arab scientists and engineers who are working and conducting research in the top-notch organizations to transfer their knowledge to Arab youth. We are aiming to boost entrepreneurship among youth in Arabic world by educating, guiding, and funding them.</p>
								<img src={tunnel} alt="vision"/>
							</div>
						</div>
					</div> 
				</div> 
			</div>
			<Statistics />
            <div className="our-instructor">
                <div className="container">
                    <div className="theme-title text-center">
						<h2>Our Board Members</h2>
						<p>People standing behind this initiative</p>
					</div>
                    <div className="row">
						{BoardMembers.map((member, index) => {
							return (
								<SingleMember
									key={index}
									photo={member.photo}
									Name={member.Name}
									job={member.job}
									facebookLink={member.facebookLink}
									linkedinLink={member.linkedinLink}
									twitterLink={member.twitterLink} />
								)
						})}
                    </div>
                </div>
            </div>
			<div className="testimonial-styleOne m-bottom0">
				<div className="container">
					<div className="theme-title text-center">
						<h2>Testimonials</h2>
						<p>Still need convincing? Check out what people are saying about Singleton. </p>
					</div>

					<div id="testimonial-carousel-one" className="carousel slide opacity-testimonial" data-ride="carousel" data-interval="5000">
						
						<ol className="carousel-indicators">
							<li data-target="#testimonial-carousel-one" data-slide-to="0" className="active tran3s" title="Jenna Simpson"><img src={vision} alt=""/></li>
							<li data-target="#testimonial-carousel-one" data-slide-to="1" className="tran3s" title="Abdus Salam"><img src={vision} alt=""/></li>
							<li data-target="#testimonial-carousel-one" data-slide-to="2" className="tran3s" title="Solayman Mi."><img src={vision} alt=""/></li>
							<li data-target="#testimonial-carousel-one" data-slide-to="3" className="tran3s" title="Giyas Uddin"><img src={vision} alt=""/></li>
							<li data-target="#testimonial-carousel-one" data-slide-to="4" className="tran3s" title="Alom Bepari"><img src={vision} alt=""/></li>
							<li data-target="#testimonial-carousel-one" data-slide-to="5" className="tran3s" title="Solayman Mi."><img src={vision} alt=""/></li>
						</ol>

					  	<div className="main-wrapper">
					  		<div className="shadow"></div>
						  	<div className="carousel-inner">
							    <div className="carousel-item active">
								    <img src={vision} alt=""/>
								    <h6>Jenna Simpson</h6>
								    <span>Dropbox</span>
								    <p>As we passed, I remarked a beautiful church-spire rising above some old elms in the park; and before them, in the midst of a lawn, and some outhouses, an old red house with tall chimneys covered with ivy, and the windows shining in the sun.</p>
							    </div>

							    <div className="carousel-item">
							    	<img src={vision} alt=""/>
								    <h6>Abdus Salam</h6>
								    <span>Google</span>
								    <p>As we passed, I remarked a beautiful church-spire rising above some old elms in the park; and before them, in the midst of a lawn, and some outhouses, an old red house with tall chimneys covered with ivy, and the windows shining in the sun.</p>
							    </div>
								<div className="carousel-item">
							    	<img src={vision} alt=""/>
								    <h6>Solayman Mi.</h6>
								    <span>Mega Nz</span>
								    <p>As we passed, I remarked a beautiful church-spire rising above some old elms in the park; and before them, in the midst of a lawn, and some outhouses, an old red house with tall chimneys covered with ivy, and the windows shining in the sun.</p>
							    </div>

							    <div className="carousel-item">
							    	<img src={vision} alt=""/>
								    <h6>Giyas Uddin</h6>
								    <span>Upwork</span>
								    <p>As we passed, I remarked a beautiful church-spire rising above some old elms in the park; and before them, in the midst of a lawn, and some outhouses, an old red house with tall chimneys covered with ivy, and the windows shining in the sun.</p>
							    </div>

							    <div className="carousel-item">
							    	<img src={vision} alt=""/>
								    <h6>Alom Bepari</h6>
								    <span>Google</span>
								    <p>As we passed, I remarked a beautiful church-spire rising above some old elms in the park; and before them, in the midst of a lawn, and some outhouses, an old red house with tall chimneys covered with ivy, and the windows shining in the sun.</p>
							    </div>

							    <div className="carousel-item">
							    	<img src={vision} alt=""/>
								    <h6>Solayman Mi.</h6>
								    <span>Mega Nz</span>
								    <p>As we passed, I remarked a beautiful church-spire rising above some old elms in the park; and before them, in the midst of a lawn, and some outhouses, an old red house with tall chimneys covered with ivy, and the windows shining in the sun.</p>
							    </div>
						  	</div>
							<a className="left carousel-control" href="#testimonial-carousel-one" data-slide="prev">
							    <i className="fas fa-chevron-circle-left" aria-hidden="true"></i>
							    <span className="sr-only">Previous</span>
							</a>
							<a className="right carousel-control" href="#testimonial-carousel-one" data-slide="next">
							    <i className="fas fa-chevron-circle-right" aria-hidden="true"></i>
							    <span className="sr-only">Next</span>
							</a>
					  	</div>
					</div>
				</div>
			</div>

			<div className="container contact-us-page">
	        	<div className="row">
	        		<div className="col-md-6 col-sm-12 col-xs-12 wow fadeInLeft">
	        			<div className="contact-us-form">
	        				<h2>Send a Message</h2>

	        				<form className="form-validation" autoComplete="off">
	        					<div className="row">
	        						<div className="col-sm-12">
	        							<div className="single-input">
	        								<input type="text" placeholder="You Name*" name="name"/>
	        							</div>
	        						</div>
	        						<div className="col-sm-6 col-xs-12">
	        							<div className="single-input">
	        								<input type="email" placeholder="Enter Email here*" name="email"/>
	        							</div>
	        						</div>
	        						<div className="col-sm-6 col-xs-12">
	        							<div className="single-input">
	        								<input type="text" placeholder="Phone Number" name="phone"/>
	        							</div>
	        						</div>
	        						<div className="col-sm-12">
	        							<div className="single-input">
	        								<textarea placeholder="Your Message" name="message"></textarea>
	        							</div>
	        						</div>
	        					</div>
	        					<input type="submit" value="Send Message" className="tran3s p-bg-color"/>
	        				</form>

							<div className="alert-wrapper" id="alert-success">
								<div id="success">
									<button className="closeAlert"><i className="fa fa-times" aria-hidden="true"></i></button>
									<div className="wrapper">
						               	<p>Your message was sent successfully.</p>
						             </div>
						        </div>
						    </div>
						    <div className="alert-wrapper" id="alert-error">
						        <div id="error">
						           	<button className="closeAlert"><i className="fa fa-times" aria-hidden="true"></i></button>
						           	<div className="wrapper">
						               	<p>Sorry!Something Went Wrong.</p>
						            </div>
						        </div>
						    </div>
	        			</div>
	        		</div>

	        		<div className="col-md-6 col-sm-12 col-xs-12 wow fadeInRight">
	        			<div className="contactUs-address">
							<h2>Contact Information</h2>
			        		<p>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form,</p>
			        		<div className="single-address clearfix">
			        			<div className="icon float-left"><i className="fas fa-map-marked-alt"></i></div>
			        			<div className="text float-left">
			        				<h6>Address</h6>
			        				<span>Espoo, Finland</span>
			        			</div>
			        		</div>
			        		<div className="single-address clearfix">
			        			<div className="icon float-left"><i className="fas fa-envelope"></i></div>
			        			<div className="text float-left">
			        				<h6>Email</h6>
			        				<span>aseeu.2018@gmail.com</span>
			        			</div>
			        		</div>
			        		<div className="single-address clearfix">
			        			<div className="icon float-left"><i className="fas fa-phone-square"></i></div>
			        			<div className="text float-left">
			        				<h6>Phone</h6>
			        				<span>+358414970371</span>
			        			</div>
			        		</div> 
						</div>
	        		</div>
	        	</div>
	        </div>

        </div>
    )
}