import React from "react";
import Vacancies from "../announcements/Vacancies";
import PartnerWithUs from "./PartnerWithUs";
import InvestWithUs from "./InvestWithUs";
// import deepLearning from "../../images/deepLearning.jpg";

export default function Joinus() {
    return (
        <div className="joinus-page">
            <div className="parallax-backGround">
                <div className="Head-AboutUs">
                    <div className="row">
                        <div className="col head-slide">
                            <h1 className="animate__animated animate__backInDown"> Ready to create a new reality with us !</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="learn_more_button">
                            <a href="#VacanciesPage">Vacancies</a>
                        </div>
                        <div className="learn_more_button">
                            <a href="#our-partners">Teach with us</a>
                        </div>
                        <div className="learn_more_button">
                            <a href="#our-investors">Invest with us</a>
                        </div>
                        <div className="learn_more_button">
                            <a href="#about-US">Contact us</a>
                        </div>
                    </div>
                </div>
            </div>
            <Vacancies />
            <hr/>
            <PartnerWithUs />
			<InvestWithUs />
            <div className="container contact-us-page">
	        	<div className="row">
	        		<div className="col-md-6 col-sm-12 col-xs-12 wow fadeInLeft">
	        			<div className="contact-us-form">
	        				<h2>Send a Message</h2>
	        				<form className="form-validation" autoComplete="off">
	        					<div className="row">
	        						<div className="col-sm-12">
	        							<div className="single-input">
	        								<input type="text" placeholder="You Name*" name="name"/>
	        							</div>
	        						</div>
	        						<div className="col-sm-6 col-xs-12">
	        							<div className="single-input">
	        								<input type="email" placeholder="Enter Email here*" name="email"/>
	        							</div>
	        						</div>
	        						<div className="col-sm-6 col-xs-12">
	        							<div className="single-input">
	        								<input type="text" placeholder="Phone Number" name="phone"/>
	        							</div>
	        						</div>
	        						<div className="col-sm-12">
	        							<div className="single-input">
	        								<textarea placeholder="Your Message" name="message"></textarea>
	        							</div>
	        						</div>
	        					</div>
	        					<input type="submit" value="Send Message" className="tran3s p-bg-color"/>
	        				</form>

							<div className="alert-wrapper" id="alert-success">
								<div id="success">
									<button className="closeAlert"><i className="fa fa-times" aria-hidden="true"></i></button>
									<div className="wrapper">
						               	<p>Your message was sent successfully.</p>
						             </div>
						        </div>
						    </div>
						    <div className="alert-wrapper" id="alert-error">
						        <div id="error">
						           	<button className="closeAlert"><i className="fa fa-times" aria-hidden="true"></i></button>
						           	<div className="wrapper">
						               	<p>Sorry!Something Went Wrong.</p>
						            </div>
						        </div>
						    </div>
	        			</div>
	        		</div>

	        		<div className="col-md-6 col-sm-12 col-xs-12 wow fadeInRight" id="about-US">
	        			<div className="contactUs-address">
							<h2>Contact Information</h2>
			        		<p>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form,</p>
			        		<div className="single-address clearfix">
			        			<div className="icon float-left"><i className="fas fa-map-marked-alt"></i></div>
			        			<div className="text float-left">
			        				<h6>Address</h6>
			        				<span>Espoo, Finland</span>
			        			</div>
			        		</div>
			        		<div className="single-address clearfix">
			        			<div className="icon float-left"><i className="fas fa-envelope"></i></div>
			        			<div className="text float-left">
			        				<h6>Email</h6>
			        				<span>aseeu.2018@gmail.com</span>
			        			</div>
			        		</div>
			        		<div className="single-address clearfix">
			        			<div className="icon float-left"><i className="fas fa-phone-square"></i></div>
			        			<div className="text float-left">
			        				<h6>Phone</h6>
			        				<span>+358414970371</span>
			        			</div>
			        		</div> 
						</div>
	        		</div>
	        	</div>
	        </div>
        </div>
    )
}