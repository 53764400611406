import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, NavLink } from "react-router-dom";
// import classnames from "classnames";
import projectLogo from "../images/logoFocused.png";
import Mainform from "./Mainform/Mainform";
import Entrepreneur from "./entrepreneur/Entrepreneur";
import MainPage from "./MainPage";
// import AboutUs from "./AbotUs/AboutUs";
import AboutUsNew from "./AbotUs/AboutUsNew";
import LearningPathEN from "./LearningPath/LearningPathEN";
import Education from "./OnlineEducation/Education";

import InstructorSignUpForm from "./OnlineEducation/InstructorSignUpForm";
import ScholarshipForm from "./OnlineEducation/ScholarshipForm";
import InvestForm from "./Joinus/InvestForm";

import ComputerVision from "./OnlineEducation/ComputerVision";
import ReactCourse from "./OnlineEducation/ReactCourse";
import MachineLearning from "./OnlineEducation/MachineLearning";
import DeepLearning from "./OnlineEducation/DeepLearning";
import ROS from "./OnlineEducation/ROS";
import SolidWorks from "./OnlineEducation/SolidWorks";
import Joinus from "./Joinus/Joinus";

function RoutingEN() {
  const [windowHeight, setHeight] = useState({
    scrollState: 0,
    newClassName: "navigation_bar"
  });
  // const [scrollgo, setScrollgo] = useState("navbar navbar-default");
  const handleScroll = () => {
    const currentYoffset = window.pageYOffset;
    setHeight((prev) => {
      
      if (currentYoffset > prev.scrollState) {
        // console.log("scroll up");
        return {
          scrollState: currentYoffset,
          newClassName: "navigation_bar navbar--scrolldown"
        }
      } else if(currentYoffset < prev.scrollState  && currentYoffset>100) {
        return {
          scrollState: currentYoffset,
          newClassName: "navigation_bar navbar--scrollup"
        }
      }
      else {
        // console.log("scroll down");
        return {
          scrollState: currentYoffset,
          newClassName: "navigation_bar"
        }
      }
    });
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  },[])

  return (
    <div className="project_body">
      <Router>
        <div>
        <nav className={windowHeight.newClassName}>
            <h1>
              <NavLink className="header-of-navigationBar" to="/">
                <img src={projectLogo} alt="ASEEU"/>
              </NavLink>
            </h1>
            <div className="navigation_bar_list">
              <ul>
                <li>
                  <NavLink className="c-tab-navigation__button" to="/aboutus">
                    <i className="fas fa-users"></i>
                    <div className="navigation_button-wrabber">About us</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="c-tab-navigation__button" to="/education">
                    <i className="fas fa-university"></i>
                    <div className="navigation_button-wrabber">Arabs Technopedia</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="c-tab-navigation__button" to="/entrepreneur">
                    <i className="fas fa-briefcase"></i>
                    <div className="navigation_button-wrabber">entrepreneur</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="c-tab-navigation__button" to="/learningPath">
                    <i className="fas fa-road"></i>
                    <div className="navigation_button-wrabber">Learning paths</div>
                  </NavLink>
                </li>
                <li className="signUp_button">
                  <NavLink className="c-tab-navigation__button" to="/joinus">
                    <i className="fas fa-hands-helping"></i>
                    <div className="navigation_button-wrabber">Join us</div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/joinus">
              <Joinus />
            </Route>
            <Route path="/signUp">
              <Mainform />
            </Route>
            <Route path="/learningPath">
              <LearningPathEN />
            </Route>
            <Route path="/entrepreneur">
              <Entrepreneur />
            </Route>
            <Route path="/education">
              <Education />
            </Route>
            <Route path="/computer-vision">
              <ComputerVision />
            </Route>
            <Route path="/react-course">
              <ReactCourse />
            </Route>
            <Route path="/machine-learning">
              <MachineLearning />
            </Route>
            <Route path="/deep-learning">
              <DeepLearning />
            </Route>
            <Route path="/ROS">
              <ROS />
            </Route>
            <Route path="/solid-works">
              <SolidWorks />
            </Route>
            <Route path="/instructor-signup">
              <InstructorSignUpForm />
            </Route>
            <Route path="/scholarship-form">
              <ScholarshipForm />
            </Route>
            <Route path="/invest-form">
              <InvestForm />
            </Route>
            <Route path="/aboutus">
              <AboutUsNew />
            </Route>
            <Route path="/">
              <MainPage />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default RoutingEN;
