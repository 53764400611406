import React from "react";
export type typeprops = {
    key: number;
    vacancyTitle: string;
    VacancyDescription: string;
    VacancyCategory: string;
    jobRequirements: string[];
    Application: string;
    deadLine: string;
    publishDate: string
}
function VacancyDetailed(props: typeprops) {

    return (
        <div>
            <table>
                <tr>
                    <td>
                        <p>Job Title:</p>
                        <p>{props.vacancyTitle}</p>
                    </td>
                    <td>
                        <p>Job Type:</p>
                        <p>{props.VacancyCategory}</p>
                    </td>
                    <td className="extra_column-to_style">
                        <p>Job Category:</p>
                        <p>Web Development</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Posting Date:</p>
                        <p>{props.publishDate}</p>
                    </td>
                    <td>
                        <p>Application Deadline:</p>
                        <p>{props.deadLine}</p>
                    </td>
                </tr>
            </table>
            <div className="VacancyDetails-text">
                <h3>Description:</h3>
                <p>{props.VacancyDescription}</p>
                <h3>Requirements:</h3>
                <ul>
                    {props.jobRequirements.map((requirementRow) => {
                        return <li>{requirementRow}</li>
                    })}
                </ul>
                <h3>Apply for Vacancy:</h3>
                <p>{props.Application}</p>
            </div>
        </div>
    );
}

export default VacancyDetailed;