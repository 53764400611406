import React from "react";
import Input from "./Input";

interface typeprops {
  Bname: string;
}

function Form(props: typeprops) {

  return (
    <form className="logging-form">
      <Input type="text" placeholder="Username" />
      <Input type="password" placeholder="Password" />
      {props.Bname === "register" && (
        <Input type="password" placeholder="Confirm Password" />
      )}
      <button type="submit">
        {props.Bname}
      </button>
    </form>
  );
}

export default Form;
