import React from "react";
import businessMan from "../../images/confident.png";
// import money1 from "../../images/iconsJoinUs/money.svg";
import charity from "../../images/iconsJoinUs/charity.svg";
import transparency from "../../images/iconsJoinUs/transparency.svg";

// import commitment from "../../images/trustness.svg";

export default function InvestWithUs() {
    return (
        <div className="our-investors" id="our-investors">
            <div className="theme-title text-center">
                <h2>Investing in the future</h2>
            </div>
            {/* Rules Section for investing on the platform */}
            <div className="row investor-mission">
                <div className="col-md-6 col-sm-12">
                    <div className="theme-title">
                        <h4>Why you should consider investing with us?</h4>
                    </div>
                    <div className="investor-mission-details">
                        <p> We are following a closed loop business model to ensure the sustainability of our services. This means that the major portion of our profits is pumped back to improve our digital platform and the services that we could offer. So, as an investor in our digital platform, your first motive should be on supporting the mission of this initiative rather than maximizing your financial gains; However, you will be able to secure some profits.
                        Only 30 % of profits is available for shareholders, while 70% of these profits are pumped back in the digital platform.</p>
                        {/* <h4>If any doupts, Don't hesitate to contact us</h4> */}
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <img src={charity} alt="confusion"/>
                </div>
            </div>

            <div className="row investor-policy">
                <div className="col-md-6 col-sm-12">
                    <img src={transparency} alt="confusion"/>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="theme-title">
                        <h4>Investing policy that guarantee full transparency</h4>
                    </div>
                    <div className="investor-mission-details">
                        <p> We are digitalizing our services. This means that even managing the investors affairs is done through digital means. As an investor, you will monitor and follow all our company’s activities, liabilities, your equity, and the equity of all members in the company.The decision making in the company is done through voting by ratifying votes of shareholders who possess the biggest portion in the company’s equity. Everything is done and managed securely through the digital platform.</p>
                        {/* <h4>If any doupts, Don't hesitate to contact us</h4> */}
                    </div>
                </div>
            </div>

            <div className="row investor-banner">
                <div className="col-md-6 col-sm-12">
                    <h1>Share us the vision for a prosper MENA region</h1>
                    <p> If you share us the same values and willing to contribute to a meaningfull work, join us!</p>
                    <div className="learn_more_button button-partner">
                        <a href="/invest-form">Invest with us<i className="fas fa-arrow-circle-right"></i></a>
                    </div>
                    {/* <div className="exploremore-partners">
                        <p>Explore the possible tracks to be an instuctor on Arabs Technopedia</p>
                        <i className="fas fa-angle-double-down"></i>
                    </div> */}
                </div>
                <div className="col-md-6 col-sm-12 investor-thumbnail">
                    <img src={businessMan} alt="businessMan"/>
                </div>
            </div>
        </div>
    )
}