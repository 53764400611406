import React, { useState } from "react";
import teacher from "../../images/english-teacher.png";
// import money1 from "../../images/iconsJoinUs/money.svg";
import money2 from "../../images/iconsJoinUs/save-money.svg";
import expertsClub from "../../images/iconsJoinUs/007-innovation.svg";
import leader from "../../images/iconsJoinUs/020-leader.svg";
import confusion from "../../images/iconsJoinUs/confusion.svg";
import commitment from "../../images/trustness.svg";
export default function PartnerWithUs() {
    const [detailOpen1, setDetailOpen] = useState(false);
    const [detailOpen2, setDetailOpen2] = useState(false);


    function trackOpen1() {
        setDetailOpen(!detailOpen1);
        console.log("element Clicked!")
    }
    
    function trackOpen2() {
        setDetailOpen2(!detailOpen2);
        console.log("element Clicked!")
    }
    return (
        <div className="our-partners" id="our-partners">
            <div className="theme-title text-center">
                <h2>Become an instructor</h2>
            </div>
            <div className="meet_objectives-parallax educateWithUs-background">
                <div className="meet_objectives">
                    <div className="meet_objectives--tiltle"><h1>Benefits of teaching on Arabs Technopedia</h1></div>
                    <div className="note_all withTitle">
                        <div className="note">
                            <img src={leader} alt="jobHunt" width="200" height="150"/>
                            <h1> Bring change </h1>
                            <p>The main core of our organization to make a positive impact in other's live. By teaching other people in their native language and through our scholarships program, you will help thousands to realize their dreams.</p>
                        </div>
                        <div className="note">
                            <img src={expertsClub} alt="launchStart" width="200" height="150"/>
                            <h1> Build a unique network </h1>
                            <p>Joining our experts club is a priceless experience. It will enable you to collaborate with special people in their own fields and you will have a full support from our network if you decided to start your own business someday.</p>
                        </div>
                        <div className="note">
                            <img src={money2} alt="entrepreneur" width="200" height="150"/>
                            <h1> Earn some money</h1>
                            <p>While you are working to make a real impact in this world, We will make sure that you generate some profits from your work as we know that your work as a tutor requires much time and effort dedication.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row partnership-banner">
                <div className="col-md-6 col-sm-12">
                    <h1>Make a real impact</h1>
                    <p> Jump to another level of expertise in your field by teaching it to other people and earn some money</p>
                    <div className="learn_more_button button-partner">
                        <a href="/instructor-signup">teach with us<i className="fas fa-arrow-circle-right"></i></a>
                    </div>
                    <div className="exploremore-partners">
                        <p>Explore the possible tracks to be an instuctor on Arabs Technopedia</p>
                        <i className="fas fa-angle-double-down"></i>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 partner-thumbnail">
                    <img src={teacher} alt="teacher"/>
                </div>
            </div>
            <div className="partnership-track-wrapper">
                <div className="partnership-track">
                    {/* <div className="TrackNumber">First Track</div> */}
                    <div className={detailOpen1 ? "TrackNumber":"TrackNumber--collapse"} onClick={trackOpen1}>First Track</div>
                    <div className={detailOpen1 ? "Track-opening":"Track-opening--collapse"} onClick={trackOpen1}>
                        <i className="fas fa-angle-double-down"></i>
                    </div>
                    <div className="TrackTitle">Starter instructor</div>
                </div>
                <div className={detailOpen1 ? "Track-description":"Track-description--collapse"}>
                    <ul>
                        <li>You will prepare the education material to be uploaded to the website (As an example, you will record your online lecture), while, we will handle everything else, such as video editing and montage, polishing it with entrance and exit, all necessary effects that ensure the production of high quality material and publishing it on the plateform.</li>
                        <li>We will help you to reach thousands or hundreds of thousands of students as we will make the paid advertisements on behalf of you. This means that we will endure 100% of marketing-related costs to help you reach your audience</li>
                        <li>You will be able to make a good profit from your online course when it reaches heigh number of students. If your course didn’t reach high number of students but you got a good evaluation on your educational course. We will make sure that you generate profits by applying fee on your course through our platform. So, whatever the situation, we will ensure that you generate profits from your course.</li>
                    </ul>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="theme-title">
                                <h2>Duties as a starter instructor</h2>
                            </div>
                            <div className="Track-description-benefits">
                                <p>in this track you are supposed to be a beginner content creator. You commit to follow our regulations and instructions to produce high-quality online course.</p>
                                <p>we have a marketing division which decide on the pricing policies of courses according to our organization mission and goals.</p>
                           </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <img src={commitment} alt="wallPost"/>
                        </div>
                    </div>
                </div>
                <div className="partnership-track">
                    {/* <div className="TrackNumber">Second Track</div> */}
                    <div className={detailOpen2 ? "TrackNumber":"TrackNumber--collapse"} onClick={trackOpen2}>Second Track</div>
                    <div className={detailOpen2 ? "Track-opening":"Track-opening--collapse"} onClick={trackOpen2}>
                        <i className="fas fa-angle-double-down"></i></div>
                    <div className="TrackTitle">Experienced instructor</div>
                </div>
                <div className={detailOpen2 ? "Track-description":"Track-description--collapse"}>
                    <ul>
                        <li>You will prepare the educational material. You will handle everything to ensure the production of high-quality material such as (video editing and montage, marketing of your course). You will provide us with all supplementary materials such as (course description, prerequisites for the course, your curriculum etc.), while we will build your course page and bring it into alive.</li>
                        <li>You will enjoy the merit of deciding your pricing policy as you want (it is all up to you). However, you should accept that we will charge 20% percent of every payment that done by an enrolled student into your course. These charges will be pumped back into your favour because we will use it to grant scholarships to students in need for it which will be a good marketing for your services.</li>
                        <li>If your focus is on maximizing profits, this track is the recommended one for you.</li>
                    </ul>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="theme-title">
                                <h2>Duties as a experienced instructor</h2>
                            </div>
                            <div className="Track-description-benefits">
                                <p>in this track you are supposed to be a professional content creator. You are committed to create all your course materials, edit and include explainer video to make it looking professional.</p>
                                <p>You should accept that the first month of releasing your online-course, we will be authorized to grant as much scholarships as our marketing division will decide because this will be part of our marketing campaign to promote your course and enable you to maximize your profits after this month</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <img src={commitment} alt="investor"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row confused-about-track">
                <div className="col-md-6 col-sm-12">
                    <div className="theme-title">
                        <h6>Are you confused?</h6>
                        <h2>Which track should i take?</h2>
                    </div>
                    <div className="Track-description-benefits">
                        <p> If you are confused which track you should take, you can contact us and we will guide you. However, our recommendation is to take the second track (experienced instructor) if you are a professional content creator, as an example you have substantial skills with video recording, video editing, graphics etc. If you want to teach a course related to the previously mentioned skills, we recommend you join the second track. If you have no skills with previously mentioned skills, you should apply within the first track (starter instructor)  
                        </p>
                        <h4>If any doupts, don't hesitate to contact us</h4>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <img src={confusion} alt="confusion"/>
                </div>
            </div>
        </div>
    )
}