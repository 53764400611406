
export type props = {
    key: number;
    vacancyTitle: string;
    VacancyDescription: string;
    VacancyCategory: string;
    Requirements: string[];
    Application: string;
    deadLine: string;
}

const ListOfVacancies: props[] =[{
    key: 0,
    vacancyTitle: "Full Stack Developer",
    VacancyDescription: "We are looking for an experienced developer who is comfortable with both front and back end programming. Full Stack Developers are responsible for developing and designing high-performance back-end applications and responsiveness front end web architecture. As a member of our team, the most important thing we look for in people is a passion and enthusiasm around contribution in building better societies, having a benevolent spirit, real desire to help others and working for the collective good",
    VacancyCategory: "Freelancer",
    Requirements: ["Experience with emerging cloud platform technologies such as Microsoft Azure.", "Knowledge of Design Patterns and best practices.","Excellent knowledge with ASP.NET core  and Good knowledge with ReactJs, typescript.", "Experience in database development (SQL) and NoSql database.", "Good familiarity with version control “GitHub”", "Good communication skills.", "Has a critical, creative mind and above all an excellent team worker.", "Good command in English.", "Knowledge with MobX or Redux as a state management library would be  an advantage."],
    Application: " Send your CV with a link of sample of your previous work on asseu.2018@gmail.com",
    deadLine: "28 November, 2020"
},
{
    key: 1,
    vacancyTitle: "Motion Graphics Designer",
    VacancyDescription: "Creating a professional marketing videos",
    VacancyCategory: "Freelancer",
    Requirements: [],
    Application: " Send your CV with a link of sample of your previous work on asseu.2018@gmail.com",
    deadLine: "29 November, 2020"
},
{
    key: 2,
    vacancyTitle: "UI/UX Designer",
    VacancyDescription: "Designing a beautiful user interfaces",
    VacancyCategory: "Freelancer",
    Requirements: [],
    Application: " Send your CV with a link of sample of your previous work on asseu.2018@gmail.com",
    deadLine: "30 November, 2020"
}]
export default ListOfVacancies;