import React from "react";
import img from "../../images/underConstruction.jpg"

export default function InvestForm() {
  return (
    <div className="learning_path">
      <p>Here is the investment form to be filled</p>
      <img src={img} alt="page" width="800" height="500"></img>
    </div>
  );
}

