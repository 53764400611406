import React, { useState } from "react";
import AhmedElsayes from "../../images/courses/profile_photos/AhmedElsayes.jpg";
// import OpenCV from "../../images/courses/OpenCV.jpg";
// import courses from "./CourseList";
import { Reactcourse } from "./CourseList";

import BoardMembers from "../AbotUs/BoardMembers";
// const CourseImage = {
//     backgroundImage: "url(../../images/courses/deepLearning.jpg)",
//     backgroundAttachment: "fixed",
//     backgroundPosition: "center",
//     backgroundRepeat: "no-repeat",
//     backgroundSize: "cover"
// };

const videoURI: string = "https://www.youtube.com/embed/";

export default function ReactCourse() {
    const [newVid, setNewVid] = useState("");

    function handleClick1() {
        setNewVid(Reactcourse.courseLessons[0]);
    }
    function handleClick2() {
        setNewVid(Reactcourse.courseLessons[1]);
    }
    function handleClick3() {
        setNewVid(Reactcourse.courseLessons[2]);
    }
    function handleClick4() {
        setNewVid(Reactcourse.courseLessons[3]);
    }
    function handleClick5() {
        setNewVid(Reactcourse.courseLessons[4]);
    }
    function handleClick6() {
        setNewVid(Reactcourse.courseLessons[5]);
    }
    function handleClick7() {
        setNewVid(Reactcourse.courseLessons[6]);
    }
    function handleClick8() {
        setNewVid(Reactcourse.courseLessons[7]);
    }
    function handleClick9() {
        setNewVid(Reactcourse.courseLessons[8]);
    }
    function handleClick10() {
        setNewVid(Reactcourse.courseLessons[9]);
    }
    function handleClick11() {
        setNewVid(Reactcourse.courseLessons[10]);
    }
    function handleClick12() {
        setNewVid(Reactcourse.courseLessons[11]);
    }
    function handleClick13() {
        setNewVid(Reactcourse.courseLessons[12]);
    }
    function handleClick14() {
        setNewVid(Reactcourse.courseLessons[13]);
    }
    function handleClick15() {
        setNewVid(Reactcourse.courseLessons[14]);
    }
    function handleClick16() {
        setNewVid(Reactcourse.courseLessons[15]);
    }
    function handleClick17() {
        setNewVid(Reactcourse.courseLessons[16]);
    }
    function handleClick18() {
        setNewVid(Reactcourse.courseLessons[17]);
    }
    function handleClick19() {
        setNewVid(Reactcourse.courseLessons[18]);
    }
    function handleClick20() {
        setNewVid(Reactcourse.courseLessons[19]);
    }
    function handleClick21() {
        setNewVid(Reactcourse.courseLessons[20]);
    }
    function handleClick22() {
        setNewVid(Reactcourse.courseLessons[21]);
    }
    function handleClick23() {
        setNewVid(Reactcourse.courseLessons[22]);
    }
    function handleClick24() {
        setNewVid(Reactcourse.courseLessons[23]);
    }
    return (
        <div className="wrapper-course-details">
            <div className="Head-course-details-parallax">
                <div className="course-category-heighlight">
                    <h1>Web Development</h1>
                </div>
            </div>
            <div className="course-details">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-xs-12">
                        <div className="details-wrapper">
                            <div className="course-title">
                                <h2>{Reactcourse.header}</h2>
                                <span>Posted on 20 Dec, 2020</span>
                            </div>
                            <div id="courseWatchingPanel" className="course-info row">
                                <div className="col-sm-4 col-xs-6">
                                    <div>
                                        <i className="far fa-clock" style={{fontSize:"50px", marginTop:"15px"}}></i>
                                        <p>Duration</p>
                                        <b>18 lesson - 24 Hours</b>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-xs-6">
                                    <div>
                                        <i className="fas fa-bookmark" style={{fontSize:"50px", marginTop:"15px"}}></i>
                                        <p>Main Category</p>
                                        <b>{Reactcourse.courseCategory[0]}</b>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-xs-6">
                                    <div>
                                        <i className="fas fa-star" style={{fontSize:"50px", marginTop:"15px"}}></i>
                                        <p><b>4.5</b> (23)</p>
                                        <ul>
                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                            <li><i className="fa fa-star-half-o" aria-hidden="true"></i></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> 
                            {/* Course image is placed Here */}
                            <iframe title="tutorial" frameBorder="0" src={videoURI+newVid} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            {/* <img src={OpenCV} alt="trialImage"/> */}
                            <p className="p1">{Reactcourse.body}</p>
                            <div className="learn-list">
                                <h3>What Will I Learn?</h3>
                                <ul className="row">
                                    {Reactcourse.courseOutcomes.map((item, index) => {
                                        return (
                                            <li key={index} className="col-sm-6 col-xs-12"><span>{item}</span></li>
                                        )
                                    })}
                                </ul>
                            </div>

                            <div className="curriculum-panel">
                                <div className="clearfix">
                                    <h3 className="float-left">Curriculum</h3>
                                    <ul className="float-right">
                                        <li><span>6 </span> Sections</li>
                                        <li><span>40 : 30</span></li>
                                    </ul>
                                </div>
                                <div className="panel-group theme-accordion" id="accordionTwo">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h5 className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordionTwo" href="#collapse11" className="clearfix">
                                                    <h6 className="float-left">
                                                        <span>Section 1</span>
                                                        Introduction to ReactJs technology
                                                    </h6>
                                                    <p className="float-right">15:12min</p>
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapse11" className="panel-collapse collapse in">
                                            <div className="panel-body">
                                                <ul>
                                                    <li className="clearfix">
                                                        <span className="float-left"><a href="#courseWatchingPanel" className="tran3s"><i className="far fa-file-alt" aria-hidden="true"></i> Tools needed and hint about prerequisite for the course</a></span>
                                                        <span className="float-right">75kb</span>
                                                    </li>
                                                    <li className="clearfix">
                                                        <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick1}><i className="fas fa-play-circle" aria-hidden="true"></i> motivation about ReactJs and it's future </a></span>
                                                        <span className="float-right">9:32</span>
                                                    </li>
                                                    <li className="clearfix">
                                                        <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick2}><i className="fas fa-play-circle" aria-hidden="true"></i> Setting up our environment and first code </a></span>
                                                        <span className="float-right">8:04</span>
                                                    </li>
                                                    <li className="clearfix">
                                                        <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick3}><i className="fas fa-play-circle" aria-hidden="true"></i> How ReactJs working: Babel compiler </a></span>
                                                        <span className="float-right">9:49</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel">
                                        <div className="panel-heading">
                                        <h5 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordionTwo" href="#collapse22" className="clearfix">
                                                <h6 className="float-left">
                                                    <span>Section 2</span>
                                                    Basics of React JS and use of ES6 functions 
                                                </h6>
                                                <p className="float-right">13:41min</p>
                                            </a>
                                        </h5>
                                        </div>
                                        <div id="collapse22" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <ul>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s"><i className="far fa-file-alt" aria-hidden="true"></i> What you will learn in this section</a></span>
                                                    <span className="float-right">75kb</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick4}><i className="fas fa-play-circle" aria-hidden="true"></i> Understanding code structure inside React </a></span>
                                                    <span className="float-right">18:16min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick5}><i className="fas fa-play-circle" aria-hidden="true"></i> CSS styling in React </a></span>
                                                    <span className="float-right">5:15min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick6}><i className="fas fa-play-circle" aria-hidden="true"></i> Building React Components and rendering it </a></span>
                                                    <span className="float-right">11:25min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick7}><i className="fas fa-play-circle" aria-hidden="true"></i> Use of state Hook for building dynamic user interface </a></span>
                                                    <span className="float-right">20:20min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick8}><i className="fas fa-play-circle" aria-hidden="true"></i> Use of ES6 javaScript syntax: arrow and map functions </a></span>
                                                    <span className="float-right">19:09min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick9}><i className="fas fa-play-circle" aria-hidden="true"></i> Maping through array of objects in React and key assignment </a></span>
                                                    <span className="float-right">14:52min</span>
                                                </li>

                                            </ul>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="panel">
                                        <div className="panel-heading">
                                        <h5 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordionTwo" href="#collapse32" className="clearfix">
                                                <h6 className="float-left">
                                                    <span>Section 3</span>
                                                    shifting from JavaScript to TypeScript
                                                </h6>
                                                <p className="float-right">19:52</p>
                                            </a>
                                        </h5>
                                        </div>
                                        <div id="collapse32" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <ul>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s"><i className="far fa-file-alt" aria-hidden="true"></i> What you will learn in this section</a></span>
                                                    <span className="float-right">75kb</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick10}><i className="fas fa-play-circle" aria-hidden="true"></i> Introduction into TypeScript </a></span>
                                                    <span className="float-right">12:27min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick11}><i className="fas fa-play-circle" aria-hidden="true"></i> Setting up TypeScript and first code </a></span>
                                                    <span className="float-right">9:24min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick12}><i className="fas fa-play-circle" aria-hidden="true"></i> Available types in Type script and working with objects </a></span>
                                                    <span className="float-right">17:15min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick13}><i className="fas fa-play-circle" aria-hidden="true"></i> Defining classes in TypeScript </a></span>
                                                    <span className="float-right">12:20min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick14}><i className="fas fa-play-circle" aria-hidden="true"></i> Defining Subclasses in TypeScript </a></span>
                                                    <span className="float-right">13:40min</span>
                                                </li>
                                            </ul>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="panel">
                                        <div className="panel-heading">
                                        <h5 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordionTwo" href="#collapse42" className="clearfix">
                                                <h6 className="float-left">
                                                    <span>Section 4</span>
                                                    Build a complete application with ReactJs and TypeScript
                                                </h6>
                                                <p className="float-right">19:52</p>
                                            </a>
                                        </h5>
                                        </div>
                                        <div id="collapse42" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <ul>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s"><i className="far fa-file-alt" aria-hidden="true"></i> What you will learn in this section</a></span>
                                                    <span className="float-right">75kb</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick15}><i className="fas fa-play-circle" aria-hidden="true"></i> Setting up React App and building the initial structure of platform </a></span>
                                                    <span className="float-right">35:29min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick16}><i className="fas fa-play-circle" aria-hidden="true"></i>Activating  button to submit all note information </a></span>
                                                    <span className="float-right">23:43min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick17}><i className="fas fa-play-circle" aria-hidden="true"></i> Activating delete button to remove specific note </a></span>
                                                    <span className="float-right">10:13min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick18}><i className="fas fa-play-circle" aria-hidden="true"></i> Using react-router module to build navigation bar </a></span>
                                                    <span className="float-right">23:20min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick19}><i className="fas fa-play-circle" aria-hidden="true"></i> Building the switchable registeration form with drop-down menu </a></span>
                                                    <span className="float-right">24:38min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick20}><i className="fas fa-play-circle" aria-hidden="true"></i> Using Bootstrap inside React App to improve styling </a></span>
                                                    <span className="float-right">15:00min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick21}><i className="fas fa-play-circle" aria-hidden="true"></i> Finalize styling and configuring TypeScript on our App </a></span>
                                                    <span className="float-right">7:57min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick22}><i className="fas fa-play-circle" aria-hidden="true"></i> Using TypeScript to define all variables in all components </a></span>
                                                    <span className="float-right">27:15min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick23}><i className="fas fa-play-circle" aria-hidden="true"></i> Using material UI to build drop-down menu and adding props to the note </a></span>
                                                    <span className="float-right">22:45min</span>
                                                </li>
                                                <li className="clearfix">
                                                    <span className="float-left"><a href="#courseWatchingPanel" className="tran3s" onClick={handleClick24}><i className="fas fa-play-circle" aria-hidden="true"></i> Using material UI icons to improve styling and finalize our App </a></span>
                                                    <span className="float-right">27:27min</span>
                                                </li>

                                            </ul>
                                        </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="course-feedback">
                                <h3>Students Feedback</h3>
                                <div className="feedback-container">
                                    <ul className="clearfix">
                                        <li className="float-left">
                                            <h2>4.9</h2>
                                            <p>Avarage rating (9)</p>
                                            <ul>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star-half-o" aria-hidden="true"></i></li>
                                            </ul>
                                        </li>
                                        {/* modification needed / some li elements need to be styled */}
                                        <li className="float-left">
                                            <ul className="clearfix">
                                                <li>5 Star</li>
                                                <li><div style={{width:"77%"}}></div></li>
                                                <li>87%</li>
                                                <li>(5 Reviews)</li>
                                            </ul>
                                            <ul className="clearfix">
                                                <li>4 Star</li>
                                                <li><div style={{width:"70%"}}></div></li>
                                                <li>70%</li>
                                                <li>(2 Reviews)</li>
                                            </ul>
                                            <ul className="clearfix">
                                                <li>3 Star</li>
                                                <li><div style={{width:"60%"}}></div></li>
                                                <li>32%</li>
                                                <li>(1 Reviews)</li>
                                            </ul>
                                            <ul className="clearfix">
                                                <li>2 Star</li>
                                                <li><div style={{width:"45%"}}></div></li>
                                                <li>10%</li>
                                                <li>(1 Reviews)</li>
                                            </ul>
                                            <ul className="clearfix">
                                                <li>1 Star</li>
                                                <li><div style={{width:"2%"}}></div></li>
                                                <li>0%</li>
                                                <li>(0 Reviews)</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="single-review clearfix">
                                    <img src={AhmedElsayes} alt="" className="float-left"/>
                                    <div className="text float-left">
                                        <div className="clearfix">
                                            <div className="float-left">
                                                <h6>Ahmed Hassan</h6>
                                                <span>March 8, 2016 - 8:00am</span>
                                            </div>
                                            <ul className="float-right">
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                            </ul>
                                        </div>
                                        <p>some comment</p>
                                    </div>
                                </div>
                                <div className="single-review clearfix">
                                    <img src={AhmedElsayes} alt="" className="float-left"/>
                                    <div className="text float-left">
                                        <div className="clearfix">
                                            <div className="float-left">
                                                <h6>Hasan Abdullah</h6>
                                                <span>March 8, 2016 - 8:00am</span>
                                            </div>
                                            <ul className="float-right">
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                            </ul>
                                        </div>
                                        <p>some comment</p>
                                    </div> 
                                </div> 
                                <div className="single-review clearfix">
                                    <img src={AhmedElsayes} alt="" className="float-left"/>
                                    <div className="text float-left">
                                        <div className="clearfix">
                                            <div className="float-left">
                                                <h6>Solayman Mi.</h6>
                                                <span>March 8, 2016 - 8:00am</span>
                                            </div>
                                            <ul className="float-right">
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                            </ul>
                                        </div>
                                        <p>some comment</p>
                                    </div> 
                                </div> 
                            </div> 

                            <div className="submit-review-form">
                                <h3>Submit a Review</h3>
                                <p>Your Ratings</p>
                                <ul>
                                    <li><i className="fa fa-star-o" aria-hidden="true"></i></li>
                                    <li><i className="fa fa-star-o" aria-hidden="true"></i></li>
                                    <li><i className="fa fa-star-o" aria-hidden="true"></i></li>
                                    <li><i className="fa fa-star-o" aria-hidden="true"></i></li>
                                    <li><i className="fa fa-star-o" aria-hidden="true"></i></li>
                                </ul>
                                <form action="#">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label>Your Full Name</label>
                                            <input type="text" placeholder="Your Name"/>
                                        </div>
                                        <div className="col-sm-6">
                                            <label>E-mail</label>
                                            <input type="email" placeholder="sample@email.com"/>
                                        </div>
                                    </div>
                                    <label>Your Message</label>
                                    <textarea placeholder="Write Commnent..."></textarea>
                                    <input type="submit" value="Submit Review" className="s-bg-color"/>
                                </form>
                            </div> 
                        </div> 
                    </div> 

                    
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="course-sidebar">
                            <div className="sidebar-course-information">
                                <ul className="price clearfix">
                                    <li className="float-left"><strong className="s-color"><del>$89. <sup>99</sup></del>Free <sup>Limited Offer</sup></strong></li>
                                    <li className="float-right"><a href="#courseWatchingPanel" className="tran3s"><i className="flaticon-like"></i> </a></li>
                                </ul>
                                <ul className="info-list row">
                                    <li className="col-6"><b>Lectures :</b> 9</li>
                                    <li className="col-6"><b>Skill :</b> begainer</li>
                                    <li className="col-6"><b>Language :</b> English</li>
                                    <li className="col-6"><b>Duration :</b> 40:23</li>
                                </ul>
                                <div>
                                    <ul className="clearfix student-image">
                                        <li><img src={AhmedElsayes} alt=""/></li>
                                        <li><img src={AhmedElsayes} alt=""/></li>
                                        <li><img src={AhmedElsayes} alt=""/></li>
                                        <li><img src={AhmedElsayes} alt=""/></li>
                                        <li>
                                            <div className="image">
                                                <img src="images/course/24.jpg" alt=""/>
                                                <div className="opacity">150+</div>
                                            </div>
                                        </li>
                                    </ul>
                                    <p><b>1,625</b> Students Enroolled</p>
                                </div>
                                <a href="#courseWatchingPanel" className="tran3s s-bg-color take-course hvr-trim">Enroll</a>
                            </div> 

                            <div className="sidebar-instructor-info">
                                <h4>Instructor Info</h4>
                                <div className="wrapper">
                                    <div className="name">
                                        <img src={AhmedElsayes} alt=""/>
                                        <h6>{BoardMembers[0].Name}</h6>
                                        <span>{BoardMembers[0].job}</span>
                                        <ul>
                                            <li><a href={BoardMembers[0].facebookLink} className="tran3s"><i className="fab fa-facebook-f" aria-hidden="true"></i> </a></li>
                                            <li><a href={BoardMembers[0].linkedinLink} className="tran3s"><i className="fab fa-linkedin-in" aria-hidden="true"></i> </a></li>
                                            <li><a href={BoardMembers[0].twitterLink} className="tran3s"><i className="fab fa-twitter" aria-hidden="true"></i> </a></li>
                                        </ul>
                                    </div> 
                                    <p>{BoardMembers[0].shortDescription}</p>
                                    <a href="#courseWatchingPanel" className="tran3s p-bg-color follow hvr-trim">Know more</a>
                                </div> 
                            </div> 

                            <div className="sidebar-categories">
                                <h4>Categories</h4>
                                <div className="panel-group theme-accordion" id="accordion">
                                    {Reactcourse.courseCategory.map((item1, index) => {
                                        const collapseRef = index+1;
                                        const collapseRefStr = collapseRef.toString();
                                        return (
                                            <div className="panel" key={index}>
                                                <div className="panel-heading">
                                                    <h6 className="panel-title">
                                                        <a data-toggle="collapse" data-parent="#accordion" href={"#collapse"+collapseRefStr}>
                                                        {item1}</a>
                                                    </h6>
                                                </div>
                                                <div id={"collapse"+collapseRefStr} className="panel-collapse collapse in">
                                                    <div className="panel-body">
                                                        <ul>
                                                            {Reactcourse.courseSubCategories[index].map((subItem, idx) => {
                                                                return (
                                                                    <li key={idx}><a href="/" className="tran3s">{subItem}</a></li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div> 
                            </div> 
                        </div> 
                    </div> 
                </div> 
            </div> 
        </div>
    </div>
    )
}