import React from "react";
import ListOfVacancies from "./ListOfVacancies";

type proptype = {
    index: number
    key: number;
    title: string;
    writeDetails: Function;
  }
function Vacancy({index, title, writeDetails}: proptype) {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    // const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const mm = months[today.getMonth()];
    const yyyy = today.getFullYear();

    const recentDate = dd + ' ' + mm + ', ' + yyyy;
    // console.log(recentDate);

    function handleclick(){
        ListOfVacancies.forEach((vacant, id) => {
            if (id===index) {
                writeDetails(recentDate, id);
            };
        })
        // setVacancyDetail(selectedVacancy);
        console.log("vacancy clicked !");;
    }
    return (
        <div className="VacancyItem" onClick={handleclick}>
            <p>{title}</p>
            <i className="fas fa-arrow-circle-right"></i>
        </div>
    );
}

export default Vacancy;