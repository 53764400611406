import React from "react";
import confusion from "../../images/iconsJoinUs/confusion.svg";


export default function MachineLearning() {

    return (
        <div className="wrapper-course-details">
            <div className="Head-course-details-parallax">
                <div className="course-category-heighlight">
                    <h1>Machine Learning</h1>
                </div>
            </div>
            <div className="course-details-temporary">
                <h2> Course under preparation</h2>
                <img src={confusion} alt="confusion"/>
            </div>
        </div>
    )
}