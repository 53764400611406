import React from "react";
import {  NavLink } from "react-router-dom";

type Props = {
    key: number;
    imgLink: string;
    header: string;
    body: string;
    instructor: string;
    instructorImg: string;
    students: number;
    categories: string[];
    linkToCourse: string;
}
export default function CourseCard(cardProps:Props) {
    return (
        <div className="course">
            <div className="flip-course-inner">
                <div className="flip-course-front">
                    <img src={cardProps.imgLink} alt="course" height="200"/>
                    <h1> {cardProps.header} </h1>
                    <p className="course_description-front"> {cardProps.body} </p>
                    <div className="course_author">
                        <img alt="profile" src={cardProps.instructorImg} width="40" height="40"/>
                        <p className="author_name">{cardProps.instructor}</p>
                        <p className="course_price">{cardProps.students}
                            <span className="graduate_student">
                            <i className="fas fa-user-graduate"></i>
                            </span>
                        </p>
                    </div>
                </div>
                <div className="flip-course-back">
                    <h1> {cardProps.header} </h1>
                    <p className="course_description-back"> {cardProps.body} </p>
                    {/* Here i should apply new navigation concept to make courseDetails component that inheret all props from card component */}
                    <div className="link-to-courseDetails">
                        <NavLink className="DetailsLink" to={cardProps.linkToCourse}> Start Now </NavLink>
                    </div>
                    <div className="course-categories-miniBar">
                    {cardProps.categories.map((oneCategory) => {
                        return (
                            <div className="single-category-course">{oneCategory}</div>
                        )
                    })}
                    </div>
                </div>
            </div>
        </div>
    )
}