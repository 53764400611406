import React, {useState} from "react";
import Form from "./Form";
import SignInIcon from "../../icons/SignInIcon.svg"

// change this variable to switch between regestering and logging in
// var userState = true;

function Mainform() {

  const [userState, setUserState] = useState(false);
  function handleChange(event: any) {
    setUserState(!userState);
    // console.log(!userState);
  }

  return (
    <div className="formPage">
      <div className="containerC">
        {userState ? <Form Bname="Login" /> : <Form Bname="register" />}
        <button type="submit" onClick={handleChange}>
            <div>
                <img src={SignInIcon} alt="science"></img>
                {userState ? <p>To register form</p>:<p>To Login form</p>}
            </div>
        </button>
      </div>
    </div>
  );
}

export default Mainform;
