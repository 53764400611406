import React from "react";
import projectLogo from "../images/1.png";
import businessman from "../images/meet_objectives/businessman.svg";
import Ideas from "../images/Ideas.jpeg";
import graduate from "../images/meet_objectives/graduate.png";
import startup from "../images/meet_objectives/startup.png";
import entrepreneurship from "../images/entrepreneurship.jpg";
import educate from "../images/educateWithUs.jpg";
import road from "../images/road2.png";
import learnPathsLogo from "../images/learnPathsLogo.png";
import technopediaLogo from "../images/technopediaLogo.gif";
import teach from "../images/teach.png";
import youngHappy from "../images/youngHappy.jpg";
import medal from "../images/medal.svg";
import toolSet from "../images/toolSet.png";
import collaborate from "../images/collaborate.jpg";
// import jobHunt from "../images/jobHunt.jpg";
// import launchStart from "../images/launchStart.jpg";
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
// import SubdirectoryArrowLeftIcon from '@material-ui/icons/SubdirectoryArrowLeft';
// import images for crousel

function MainPage() {

  return (
    <div>
      <div className="Head-AboutUs-parallax">
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active slide-one">
              <div className="container">
                <div className="row">
                  <div className="col head-slide">
                    <h1 className="animate__animated animate__bounce"> One platform for planning your future </h1>
                    <p className="animate__animated animate__bounce"> Discover your passion and start now</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <img src={technopediaLogo} alt="path to future" className="pic-main-slider animate__animated animate__fadeInLeft animate__delay-2s"/>
                  </div>
                  <div className="col ">
                    <img src={projectLogo} alt="path to future" className="pic-main-slider pic-slider animate__animated animate__fadeInUp animate__delay-1s"/>
                  </div>
                  <div className="col">
                    <img src={learnPathsLogo} alt="path to future" className="pic-main-slider animate__animated animate__fadeInRight animate__delay-2s"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item slide-one">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 text-of-slider">
                    <h2 className="animate__animated animate__fadeInRight animate__delay-0.5s"> Get a clear path toward your goals </h2>
                    <p className="animate__animated animate__fadeInLeft animate__delay-1s">We live the age of dispersion. Do not waste any more time on hitting different paths and getting yourself confused. let our experts guide you to correct path without any more dispersion. Discover your passion and we will tell you which path you will take for your future.</p>
                    <div className="slider-navigation-button animate__animated animate__bounceIn animate__delay-1s">
                      <a href="#learning-routes">Learn More <i className="fas fa-arrow-circle-right"></i></a>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="img-box">
                      <img src={road} alt="path to future" className="pic-one pic-five animate__animated animate__fadeInDown animate__delay-0.5s"/>
                      <i className="fas fa-cogs pic-two animate__animated animate__fadeInLeft animate__delay-1s"></i>
                      <i className="fas fa-hands-helping pic-three animate__animated animate__fadeInRight animate__delay-1s"></i>
                      <i className="fas fa-street-view pic-four animate__animated animate__fadeInUp animate__delay-3s"></i>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="carousel-item slide-one">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 text-of-slider">
                    <h2 className="animate__animated animate__fadeInRight animate__delay-0.5s"> Spead up your experience and learn in easy way </h2>
                    <p className="animate__animated animate__fadeInLeft animate__delay-1s">Proficiency in English will not be a barrier for you anymore to get high quality education. You will not need to wait until mastering the English to start learning the latest technologies. Our experts brought these technologies to your door to learn it in your native language.</p>
                    <div className="slider-navigation-button animate__animated animate__bounceIn animate__delay-1s">
                      <a href="#introduction-to-education">Learn More <i className="fas fa-arrow-circle-right"></i></a>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="img-box">
                      <img src={teach} alt="teach" className="pic-five animate__animated animate__fadeInDown animate__delay-0.5s"/>
                      <img src={medal} alt="medal" className="pic-six animate__animated animate__bounceIn animate__delay-1s"/>
                      <img src={youngHappy} alt="youngHappy" className="pic-seven animate__animated animate__fadeInRight animate__delay-3s"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item slide-one">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 text-of-slider">
                    <h2 className="animate__animated animate__fadeInRight animate__delay-0.5s"> Turn your dream into reality </h2>
                    <p className="animate__animated animate__fadeInLeft animate__delay-1s">Giving you our experience. Bring resources between your hands and guiding you in every step to ensure being on the right track. Helping you to discover your passion and providing you with the necessary incubation to keep growth.</p>
                    <div className="slider-navigation-button animate__animated animate__bounceIn animate__delay-1s">
                      <a href="#entrepreneurship">Learn More <i className="fas fa-arrow-circle-right"></i></a>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="img-box">
                      <img src={toolSet} alt="teach" className="pic-eight animate__animated animate__fadeInDown animate__delay-0.5s"/>
                      <img src={collaborate} alt="teach" className="pic-nine animate__animated animate__fadeInLeft animate__delay-1s"/>
                      <i className="fas fa-running pic-ten animate__animated animate__fadeInLeft animate__delay-3s"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>

      {/* Here is the content of the page************************************************ */}
      {/* <hr/> */}
      <section className="skew-wrapper-howwork">
        <div className="how-work">
          <div className="how-we-work">
            <FormatQuoteIcon fontSize="large"/>
            <h1>This is how we work</h1>
          </div>
          <div className="how-work-video">
          <iframe title="aboutAseeu" src="https://www.youtube.com/embed/-D4T1eUScWY" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            {/* <img src={projectLogo} alt="projectLogo" width="700" height="400"></img> */}
          </div>
        </div>
      </section>

      {/* <hr/> */}
      <div className="main-page" id="introduction-to-education">
        <div className="main-page-image">
          {/* <iframe title="aboutAseeu" src="https://www.youtube.com/embed/-D4T1eUScWY" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
          <img src={educate} alt="educate with us"></img>
        </div>
        <div className="main-page-text">
          <FormatQuoteIcon fontSize="large"/>
          <p> our objective is to equip the youth in Arab world with the latest technologies that empowering them to compete globally and harnessing their potential for sustainable development. Get quality of education that matches to the international standards. prove your skills infront of recruiters by getting certified with us.</p>
          <div className="learn_more_button">
            <a href="/education">Learn more <i className="fas fa-arrow-circle-right"></i></a>
          </div>
        </div>
      </div>

      {/* <hr/> */}
      <section className="skew-wrapper-howwork">
        <div className="how-work" id="learning-routes">
          <div className="how-work-text">
            <FormatQuoteIcon fontSize="large"/>
            <p>After learning, Our experts will give you a full guidance to draw your career path and plan for your life.</p>
            <div className="learn_more_button">
              <a href="/learningPath">Learn more <i className="fas fa-arrow-circle-right"></i></a>
            </div>
          </div>
          <div className="how-work-video">
            <img src={Ideas} alt="Ideas"></img>
          </div>
        </div>
      </section>

      {/* <hr/> */}
      <div className="main-page" id="entrepreneurship">
        <div className="main-page-image">
          {/* <iframe title="aboutAseeu" src="https://www.youtube.com/embed/-D4T1eUScWY" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
          <img src={entrepreneurship} alt="entrepreneurship"></img>
        </div>
        <div className="main-page-text">
          <FormatQuoteIcon fontSize="large"/>
          <p> If your dream is to become an entrepreneur, you will find here all tools and guidance to realize it </p>
          <div className="learn_more_button">
            <a href="/entrepreneur">Learn more <i className="fas fa-arrow-circle-right"></i></a>
          </div>
        </div>
      </div>
      {/* <hr/> */}
      <div className="meet_objectives-parallax">
        <div className="meet_objectives">
          <div className="note_all">
            <div className="note">
              <img src={graduate} alt="jobHunt" width="200" height="150"/>
              <h1> Distinguish yourself</h1>
              <p>Technology is advancing rapidely. By learning the latest technologies, you are on the track to land the job of your dream</p>
            </div>
            <div className="note">
              <img src={startup} alt="launchStart" width="200" height="150"/>
              <h1> speed-up your learning </h1>
              <p>precise and concise training to meet your target. you don't need to wast any more time by taking unclear tracks</p>
            </div>
            <div className="note">
              <img src={businessman} alt="entrepreneur" width="200" height="150"/>
              <h1> Start your own business</h1>
              <p>get a clear path toward your goal by having a mentor and secure the necessary fund for your project</p>
            </div>
          </div>
        </div>
      </div>

      <div className="about_us" id="aboutUs_section">
        <div className="our_key_values">
          <h2> Our Values </h2>
          <table>
            <tbody>
              <tr>
                <td>
                    <i className="fas fa-users-cog"></i>
                    <p>Integrity</p>
                </td>
                <td>
                </td>
                <td>
                    <i className="fas fa-file-signature"></i>
                    <p>credibility</p>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>
                    <i className="fas fa-brain"></i>
                    <p>creativity</p>
                </td>
                <td>
                    <i className="fas fa-users"></i>
                    <p>Team working</p>
                </td>
                <td>
                    <i className="fas fa-handshake"></i>
                    <p>Commitment</p>
                </td>
              </tr>
              </tbody>
              <tbody>
              <tr>
                <td>
                    <i className="fas fa-retweet"></i>
                    <p>sustainability</p>
                </td>
                <td>
                </td>
                <td>
                    <i className="fas fa-medal"></i>
                    <p>Quality</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="follow-us">
          <div className="check-references">
            <h2>Check also</h2>
            <ul>
              <li><a href="/">Technical Forum</a></li>
              <li><a href="/">Scholarships for Arab Youth</a></li>
              <li><a href="/">Jobs for Arab Expats in Europe</a></li>
              <li><a href="/">Partner with us</a></li>
            </ul>
            <div className="SocialBar-container">
              <a href="https://www.facebook.com/Arab-Scientists-Engineers-in-Europe-ASEEU-603965863297607" className="btn-floating btn-lg btn-fb">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://www.youtube.com/channel/UC8pXo5xhUJROXuKiJ9l1j5w" className="btn-floating btn-lg btn-tw">
                <i className="fab fa-youtube"></i>
              </a>
              <a href="https://www.linkedin.com/company/aseeu" className="btn-floating btn-lg btn-li">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>

      </div>
      {/* <hr/> */}
    </div>
  );
}

export default MainPage