import React, { useState, useEffect } from "react";

function Footer() {
  const year = new Date().getFullYear();

  const courseNum = 5;
  const studentNum = 200;
  const instructorsNum = 1;
  // const [studentCount, setStudentCount] = useState(0);
  // const [courseCount, setCourseCount] = useState(0);
  // const [instructorCount, setInstructorCount] = useState(0);
  // const [triggerCount, setTriggerCount] = useState(false);
  
  // let index = 0;
  // function controlStudent() {
  //     index++;
  //     if (index<=studentNum) {
  //         setStudentCount(index);
  //     }
  // }
  // function controlProject() {
  //     index++;
  //     if (index<=instructorsNum) {
  //         setInstructorCount(index);
  //     }
  // }
  // function controlCourse() {
  //     index++;
  //     if (index<=courseNum) {
  //         setCourseCount(index);
  //     }
  //     // console.log(index);
  // }

  // const handleScroll = () => {
  //     const currentOffset = window.pageYOffset;
  //     // console.log(currentOffset);
  //     if (currentOffset+window.innerHeight >= document.body.offsetHeight) {
  //         setTriggerCount(!triggerCount);
  //     } else {
  //         setTriggerCount(!!triggerCount);
  //     }
  // }

  // useEffect(() => {
  //     window.addEventListener("scroll", handleScroll);
  //     return () => {
  //         window.removeEventListener("scroll", handleScroll);
  //       }
  // },[])

  // useEffect(() => {
  //     // console.log(triggerCount);
  //     const paramCount1 = setInterval(controlStudent, 20);
  //     const paramCount2 = setInterval(controlProject, 20);
  //     const paramCount3 = setInterval(controlCourse, 20);
  //     // console.log(index);
  //     return () => {
  //         clearInterval(paramCount1);
  //         clearInterval(paramCount2);
  //         clearInterval(paramCount3);      }
  // },[triggerCount])

  return (
    <footer>
      {/* <p>Copyright ⓒ {year}</p> */}
      <div className="bottom-footer">
        <div className="container">
          <ul className="float-right">
            <li><h3><span className="counter p-color">{courseNum}</span> Course </h3></li>
            <li><h3><span className="counter p-color">{studentNum}</span> student </h3></li>
            <li><h3><span className="counter p-color">{instructorsNum}</span> Instructor </h3></li>
          </ul>
          <p className="float-left">&copy; {year} <a href="/" className="tran3s p-color">ASEEU</a>. All rights reserved</p>		
        </div>
      </div>
    </footer>
  );
}

export default Footer;
